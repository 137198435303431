@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@100;200;300;500;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html, body {
  margin: 0;
  // overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  color: #eee;
  font-weight: 200;
  height: 100%;
}

#root {
  height: 100%;
}

h1, h2, h3, h4, h5, h6, .heading {
  font-family: 'Gluten', sans-serif;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5em;
}