.app {
  .circle {
    position: relative;
    display: grid;
    place-items: center;
    backdrop-filter: blur(10px);
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.5) radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    border: 0.5vmin solid;
    text-align: center;
    text-shadow: 0px 2px 2px black;

    padding-bottom: 0;
    padding-top: 4vmin;
    width: 85vmin;
    height: 85vmin;
    
    @media (min-width: 768px) {
      padding-bottom: 3vmin;
      padding-top: 0;
      width: 70vmin;
      height: 70vmin;
    }
    h1 {
      margin: 0;
      font-size: 8vmin;
    }
    h2 {
      margin: 0;
      margin-top: -1vmin;
      font-size: 2vmin;
    }
    p {
      font-size: 3.5vmin;
      padding: 0 14vmin;
      @media (min-width: 768px) {
        padding: 0 8vmin;
        font-size: 2.5vmin;
      }
    }
    .read-more {
      position: absolute;
      cursor: pointer;
      font-variant: small-caps;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      user-select: none;
      h2 {
        font-size: 4vmin;
        font-weight: 400;
        padding: 0;
      }
      svg {
        @keyframes hover {
          0% {
            transform: translateY(0%);
          }
          100% {
            transform: translateY(50%);
          }
        }
        filter: drop-shadow(0px 2px 1px black);
        display: inline-block;
        animation: 1.5s ease-in-out infinite alternate hover;
        width: 10%;
        margin: 0 0.5vmin;
        fill: currentColor;
      }
    }
  }
  .col {
    text-align: justify;
  }
  a {
    font-weight: 700;
    color: rgb(29, 78, 216);
    &.social-btn {
      display: inline-block;
      background: #eee;
      text-decoration: none;
      font-weight: 700;
      padding: 10px 20px;
      border-radius: 4px;
    }
  }
  [data-loading] {
    transition: opacity 0.1s;
    &[data-loading="true"] {
      opacity: 0;
    }
    &[data-loading="false"] {
      opacity: 1;
    }
  }
  .generate-button {
    font-family: 'Gluten', sans-serif;
    font-size: 22px;
    padding-top: 11px;
    max-width: 256px;
    user-select: none;
    background: rgb(29, 78, 216);
    &:active {
      background: rgb(72, 114, 230);
    }
    &[data-load="true"] {
      cursor: default;
      background: rgb(75, 75, 75)!important;
    }
  }
  .image-gallery {
    pointer-events: none;
  }
  img.image-gallery-image {
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    // width: 256px!important;
    height: 400px!important;
  }
  .team-member {
    position: relative;
    margin: 16px;
    width: 192px;
    height: 192px;
    border-radius: 999px;
    background: #eee;
  }
  .team-member-label {
    font-variant: small-caps;
    position: absolute;
    top: 100%;
    user-select: none;
    transform: translateY(-75%);
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 18px;
    div:first-child {

    }
    div:last-child {
      font-size: 20px;
    }
  }
  .team-member-socials {
    position: absolute;
    top: 0%;
    right: 0%;
    a {
      display: block;
      border-radius: 8px;
      overflow: hidden;
    }
    svg {
      width: 52px;
      height: 52px;
      fill: #1DA1F2;
      background: white;
      padding: 10px;
    }
  }
}