.app {
  height: 100%;
  .content-block {
    position: relative;
    min-width: 100%;
    &.fade-block {
      margin-top: -20vh;
      padding-top: 20vh;
    }
  }
  .fade {
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20vh);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20vh);
  }
  .content-block-bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
  }
  .content-block-bg-inner {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    &.color {
      mix-blend-mode: overlay;
    }
  }
  .video-overlay {
    backdrop-filter: blur(4px);
  }
}